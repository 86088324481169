<template>
  <div class="mb-3">
    <span class="tw-border-start fw-bolder me-3">帳單平衡</span>
    <button class="tw-btn tw-btn-success" @click="createBalance">
      新增欄位
    </button>
  </div>
  <div>
    <ul>
      <li class="mb-2" v-for="(data, index) in balances" :key="data.id">
        <input
          class="form-control w-50 d-inline-block"
          type="text"
          maxlength="300"
          placeholder="輸入原因(300字)"
          v-model="data.reson"
        />
        <input
          onwheel="this.blur()"
          class="form-control w-25 d-inline-block"
          type="number"
          placeholder="平衡金額"
          v-model="data.money"
          @change="data.money = $methods.numberToFixed(data.money)"
        />
        <button class="tw-btn tw-btn-danger ms-1" @click="delBalance(index)">
          刪除欄位
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  emits: ['fromBalanceData'],
  data() {
    return {
      balances: []
    }
  },
  created() {
    this.balances = []
    this.createBalance()
  },
  watch: {
    balances: {
      handler(val) {
        this.$emit('fromBalanceData', val)
      },
      deep: true
    },
  },
  methods: {
    // 新增 balance
    createBalance() {
      const now = new Date() * 1
      this.balances.push({
        id: now,
        reson: '',
        money: 0
      })
    },
    // 刪除 balance
    delBalance(index) {
      if (this.balances.length === 1) return this.SweetAlert('other', '至少留一個平衡給我吧ˊOˋ')
      this.balances.splice(index, 1)
    },
  },
}
</script>